import React from "react";
import { strings, localizations } from "../../util/i18n";

function Prices({ customRows, rows, isLogged }) {
  const showCustom = Array.isArray(customRows) && customRows.length > 0;

  const getTotalMargin = () => {
    const res = (
      (parseFloat(getPriceExclVat()) / parseFloat(getPurchasePrice()) - 1) *
      100
    ).toFixed(2);
    return isNaN(res) ? 0 : res;
  };

  const getPurchasePrice = () => {
    const result = rows
      .reduce(
        (acc, { product_buying_price, product_amount }) =>
          acc + product_buying_price * product_amount,
        0
      )
      .toFixed(2);
    return isNaN(result) ? 0 : result;
  };

  const getPriceExclVat = () => {
    const result = rows
      .reduce(
        (acc, { product_selling_price, product_amount }) =>
          acc + product_selling_price * product_amount,
        0
      )
      .toFixed(2);
    return isNaN(result) ? 0 : result;
  };

  const getVat = () => {
    const result = (
      (parseFloat(getPriceExclVat()) * parseFloat(localizations.taxPercent)) /
      100
    ).toFixed(2);
    return isNaN(result) ? 0 : result;
  };

  const getTotal = () => {
    const result = (
      parseFloat(getPriceExclVat()) + parseFloat(getVat())
    ).toFixed(2);
    return isNaN(result) ? 0 : result;
  };

  const getCustomVat = () => {
    const result = customRows
      .reduce(
        (acc, { custom_single_price, custom_vat_percentage, custom_amount }) =>
          acc +
          custom_amount * custom_single_price * (custom_vat_percentage / 100),
        0
      )
      .toFixed(2);
    return isNaN(result) ? 0 : result;
  };

  const getCustomPriceExclVat = () => {
    const result = customRows
      .reduce(
        (acc, { custom_single_price, custom_amount }) =>
          acc + custom_single_price * custom_amount,
        0
      )
      .toFixed(2);
    return isNaN(result) ? 0 : result;
  };

  const getCustomTotal = () => {
    const result = (
      parseFloat(getCustomPriceExclVat()) + parseFloat(getCustomVat())
    ).toFixed(2);
    return isNaN(result) ? 0 : result;
  };

  const getGrandTotalExclVat = () => {
    const result = (
      parseFloat(getPriceExclVat()) + parseFloat(getCustomPriceExclVat())
    ).toFixed(2);
    return isNaN(result) ? 0 : result;
  };

  const getGrandTotalVat = () => {
    const result = (parseFloat(getCustomVat()) + parseFloat(getVat())).toFixed(
      2
    );
    return isNaN(result) ? 0 : result;
  };

  const getGrandTotal = () => {
    const result = (
      parseFloat(getTotal()) + parseFloat(getCustomTotal())
    ).toFixed(2);
    return isNaN(result) ? 0 : result;
  };

  return (
    <>
      {isLogged ? (
        <>
          <div className="new-list__prices">
            <div className="new-list__prices-single category">
              <p>{strings.enerventProducts}</p>
            </div>
            <div className="new-list__prices-single">
              <p className="bold">{strings.marginText}</p>

              <p className="bold">{getTotalMargin()} %</p>
            </div>

            <div className="new-list__prices-single">
              <p className="bold">{strings.purchasePrice}</p>

              <p className="bold">
                {getPurchasePrice()} {localizations.offerCurrency}
              </p>
            </div>
            <div className="new-list__prices-single">
              <p className="bold">{strings.vatFree}</p>
              <p className="bold">
                {getPriceExclVat()} {localizations.offerCurrency}
              </p>
            </div>
            <div className="new-list__prices-single">
              <p>
                {strings.tax} {localizations.taxPercent}%
              </p>
              <p>
                {getVat()} {localizations.offerCurrency}
              </p>
            </div>
            <div className="new-list__prices-single">
              <p>{strings.taxablePrice}</p>
              <p>
                {getTotal()} {localizations.offerCurrency}
              </p>
            </div>
          </div>
          {showCustom && (
            <>
              <div className="new-list__prices">
                <div className="new-list__prices-single category">
                  <p>{strings.customProducts}</p>
                </div>
                <div className="new-list__prices-single">
                  <p className="bold">{strings.vatFree}</p>
                  <p className="bold">
                    {getCustomPriceExclVat()} {localizations.offerCurrency}
                  </p>
                </div>
                <div className="new-list__prices-single">
                  <p>{strings.tax}</p>
                  <p>
                    {getCustomVat()} {localizations.offerCurrency}
                  </p>
                </div>
                <div className="new-list__prices-single">
                  <p>{strings.taxablePrice}</p>
                  <p>
                    {getCustomTotal()} {localizations.offerCurrency}
                  </p>
                </div>
              </div>
              <div className="new-list__prices">
                <div className="new-list__prices-single category">
                  <p>{strings.grandTotals}</p>
                </div>
                <div className="new-list__prices-single">
                  <p className="bold">{strings.vatFree}</p>
                  <p className="bold">
                    {getGrandTotalExclVat()} {localizations.offerCurrency}
                  </p>
                </div>
                <div className="new-list__prices-single">
                  <p>{strings.tax}</p>
                  <p>
                    {getGrandTotalVat()} {localizations.offerCurrency}
                  </p>
                </div>
                <div className="new-list__prices-single">
                  <p>{strings.taxablePrice}</p>
                  <p>
                    {getGrandTotal()} {localizations.offerCurrency}
                  </p>
                </div>
              </div>
            </>
          )}
        </>
      ) : (
        <div className="new-list__prices">
          <div className="new-list__prices-single">
            <p className="bold">{strings.priceTax}</p>
            <p>
              {getGrandTotalExclVat()} {localizations.offerCurrency}
            </p>
          </div>
          <div className="new-list__prices-single">
            <p>{strings.tax}</p>
            <p>
              {getGrandTotalVat()} {localizations.offerCurrency}
            </p>
          </div>
          <div className="new-list__prices-single">
            <p>{strings.taxablePrice}</p>
            <p>
              {getGrandTotal()} {localizations.offerCurrency}
            </p>
          </div>
        </div>
      )}
    </>
  );
}

export default Prices;
