import { createStore, applyMiddleware } from "redux";

import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware from "redux-saga";
import nav from "./components/Nav/Nav.reducer";
import rootSaga from "./sagas/rootSaga";

const sagaMiddleware = createSagaMiddleware();

const initializeStore = (initialState = {}) => {
  const store = createStore(
    nav,
    initialState,
    composeWithDevTools(applyMiddleware(sagaMiddleware))
  );

  store.sagaTask = sagaMiddleware.run(rootSaga);

  return store;
};

export const createAction = (type, payload) => ({
  type,
  payload,
});

export default initializeStore();
