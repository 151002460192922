import React from "react";
import classNames from "classnames";

const NavFourthLevel = ({ items }) => (
  <div className="nav__fourth-level nav__fourth-level--desktop">
    {items
      .sort((a, b) => parseInt(a.order || 0, 10) - parseInt(b.order || 0, 10))
      .map((item, i) => (
        <div
          className={classNames(
            "nav__fourth-level-oem",
            "nav__fourth-level-oem--desktop",
            { "third-nav-element": i === 2 }
          )}
        >
          <a href={item.link}>
            <div className="nav__fourth-level-oem-name">{item.name}</div>
          </a>
          <div className="nav__fourth-level-items nav__fourth-level-items--desktop">
            {item.children &&
              item.children
                .sort(
                  (a, b) =>
                    parseInt(a.order || 0, 10) - parseInt(b.order || 0, 10)
                )
                .map((i) => {
                  return (
                    <a href={i.link}>
                      <div className="nav__fourth-level-item nav__fourth-level-item--desktop">
                        <span className="nav__fourth-level-name">{i.name}</span>
                      </div>
                    </a>
                  );
                })}
          </div>
        </div>
      ))}
  </div>
);

export default NavFourthLevel;
