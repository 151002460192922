import React from "react";
import classNames from "classnames";
import { connect } from "react-redux";
import * as selectors from "../Nav/Nav.selectors";
import { clickNav } from "../Nav/Nav.actions";

const HeaderTop = ({ homeLink, navOpen, dispatch }) => (
  <div className="main-header__top">
    <a href={homeLink}>
      <img
        src="/wp-content/themes/enervent/dist/images/e-tuotemerkki.svg"
        className="main-header__logo"
        alt="Enervent logo"
      />
    </a>
    <button
      className={classNames("hamburger", "hamburger--squeeze", {
        "is-active": navOpen,
      })}
      onClick={() => dispatch(clickNav())}
      type="button"
    >
      <span className="hamburger-box">
        <span className="hamburger-inner"></span>
      </span>
    </button>
  </div>
);

const mapStateToProps = (state) => ({
  navOpen: selectors.navOpen(state),
});

export default connect(mapStateToProps)(HeaderTop);
