import React from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import * as selectors from "./Nav.selectors";
import { clickAuxNav } from "./Nav.actions";

const AuxNav = ({ items, dispatch }) => (
  <div className="nav__aux">
    {items.map((item) => (
      <div
        className={classNames({
          "nav__aux-item": true,
          "nav__aux-item--desktop": true,
          // "nav__aux-item--active": secondLevel === item.name,
        })}
        onClick={() => dispatch(clickAuxNav(item.name))}
      >
        <a href={item.url}>
          <span className="nav__aux-title">{item.title}</span>
        </a>
      </div>
    ))}
  </div>
);

const mapStateToProps = (state) => ({
  items: selectors.auxItems(state),
});

export default connect(mapStateToProps)(AuxNav);
