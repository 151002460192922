import React from "react";
import ModalSingleItem from "./ModalSingleItem";
import { strings } from "../../util/i18n";

function Modal({
  offerlists,
  loading,
  show,
  closeModal,
  link,
  archives,
  onlyList,
}) {
  return (
    <div className={show ? "top-header__shopping-cart" : "hide"}>
      <div className="woocommerce">
        <div className="modal-cart__top">
          <div className="modal-cart__top-content">
            <span className="modal-cart__top-cart-text">
              {strings.offerLists} ({offerlists.length})
            </span>
            <span
              className="modal-cart__top-products-text"
              onClick={closeModal}
            ></span>
          </div>
        </div>

        <div className="listed-offerlists">
          {loading ? (
            <div className="listed-offerlists__single">
              <span>{strings.loadingLists}</span>
            </div>
          ) : offerlists.length > 0 ? (
            offerlists.map((offerlist) => (
              <ModalSingleItem offerlist={offerlist} onlyList={onlyList} />
            ))
          ) : (
            <div className="listed-offerlists__single">
              <span>{strings.noLists}</span>
            </div>
          )}
        </div>
        <div className="modal-cart__top">
          <div className="modal-cart__top-content">
            <a href={archives}>
              <button className="button ghost smaller-button">
                {strings.archive}
              </button>
            </a>
            <span className="modal-cart__top-cart-text">
              <a href={link}>
                <button className="green button">
                  {strings.createNewList}
                </button>
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Modal;
