import React from "react";
import useComponentVisible from "../useComponentVisible";
import Search from "./Header.search";

const SecondLevelSearch = () => {
  const { ref, isComponentVisible } = useComponentVisible(true);
  return (
    <div
      className="nav__second-level-container nav__second-level-container--desktop"
      ref={ref}
    >
      {isComponentVisible && (
        <div className="nav__search nav__search--desktop">
          <Search location="second-level" />
        </div>
      )}
    </div>
  );
};

export default SecondLevelSearch;
