import React, { useState } from "react";
import axios from "axios";
import {
  LinkOutlined,
  LoadingOutlined,
  FilePdfOutlined,
  CopyOutlined,
  ShoppingCartOutlined,
  ThunderboltOutlined,
  DeleteOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import ReactDOM from "react-dom";
import { Dropdown, Menu } from "antd";
import { strings } from "../../util/i18n";
const Case = require("case");

const ActionMenuInner = ({ rows }) => {
  const { offerStatus } = window?.enervent;
  const [text, setText] = useState(strings.actionMenu);

  // Sadly no sagas :(
  const handleMenuClick = async ({ key }) => {
    if (key === "1") {
      const dummy = document.createElement("input");
      document?.body?.appendChild(dummy);
      dummy.value = window?.location?.href;
      dummy.select();
      document?.execCommand("Copy");
      document?.body?.removeChild(dummy);
      setText(strings.copied);
      setInterval(() => {
        setText(strings.actionMenu);
      }, 3000);
    }

    if (key === "2") {
      setText(strings.working);
      const { data: cart } = await axios("/wp-json/wc/store/v1/cart/items", {
        method: "GET",
        headers: {
          Nonce: window?.enervent?.wcNonce,
        },
      });
      // Empty current cart.
      if (Array.isArray(cart) && cart.length > 0) {
        await axios(`/wp-json/wc/store/v1/cart/items`, {
          method: "DELETE",
          headers: {
            Nonce: window?.enervent?.wcNonce,
          },
        });
      }
      // Add new cart items.
      // NOTE Promise.all does not work here :(
      for (const row of rows) {
        let data = {
          id: row.product_id,
          quantity: row.product_amount,
        };
        if (row.variation) {
          data = { ...data, variation: row.variation };
        }
        await axios("/wp-json/wc/store/v1/cart/items", {
          method: "POST",
          data,
          headers: {
            Nonce: window?.enervent?.wcNonce,
          },
        });
      }
      // Reload to show results.
      window?.location?.reload(); // eslint-disable-line
    }

    if (key === "3") {
      setText(strings.working);
      window.location.href = `${window?.enervent?.createOfferList}?c=${window?.enervent?.pageId}`;
    }

    if (key === "4") {
      setText(strings.working);
      const name = Case.kebab(window?.enervent?.OfferReceiver);
      const response = await axios({
        url: window?.enervent?.offerPdfEndpoint,
        method: "GET",
        params: {
          url: window?.enervent?.permalink,
          title: window?.enervent?.OfferReceiver,
        },
        responseType: "blob",
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${name}.pdf`);
      document.body.appendChild(link);
      link.click();
      setText(strings.actionMenu);
    }

    if (key === "5") {
      window.location.href = `${window?.enervent?.accountOffers}`;
    }

    if (key === "6") {
      const url =
        "/wp-json/wp/v2/offerlists/" + window?.enervent?.pageId + "/archive";
      const body = "";
      fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-WP-Nonce": window?.enervent?.nonce,
        },
        body: body,
      });
      setText(strings.working);
      // Reload to show results.
      window?.location?.reload(); // eslint-disable-line
    }
  };

  const menuIcon =
    text === strings.actionMenu || text === strings.copied ? (
      <ThunderboltOutlined />
    ) : (
      <LoadingOutlined />
    );
  return (
    <>
      <Dropdown
        disabled={text !== strings.actionMenu}
        placement="bottom"
        overlay={
          <>
            <Menu
              className="action-menu"
              onClick={handleMenuClick}
              items={[
                {
                  label: <p>{strings.copyLink}</p>,
                  key: "1",
                  icon: <LinkOutlined style={{ color: "#7ab800" }} />,
                  onTitleClick: () => {},
                },
                {
                  label: <p>{strings.replaceCartContent}</p>,
                  key: "2",
                  icon: <ShoppingCartOutlined style={{ color: "#7ab800" }} />,
                },
                {
                  label: <p>{strings.copyOffer}</p>,
                  key: "3",
                  icon: <CopyOutlined style={{ color: "#7ab800" }} />,
                },
                {
                  label: <p>{strings.printPdf}</p>,
                  key: "4",
                  icon: <FilePdfOutlined style={{ color: "#7ab800" }} />,
                },
                {
                  label: <p>{strings.addLogo}</p>,
                  key: "5",
                  icon: <UploadOutlined style={{ color: "#7ab800" }} />,
                },
                offerStatus !== "archived"
                  ? {
                      label: <p>{strings.archiveOffer}</p>,
                      key: "6",
                      icon: <DeleteOutlined style={{ color: "#7ab800" }} />,
                    }
                  : null,
              ]}
            />
          </>
        }
      >
        <button className="button ghost actions">
          {menuIcon} {text}
        </button>
      </Dropdown>
    </>
  );
};
const actionMenuContainer = document.getElementById("offerlist-action-menu");
const ActionMenu = ({ rows }) =>
  ReactDOM.createPortal(<ActionMenuInner rows={rows} />, actionMenuContainer);

export default ActionMenu;
