import React, { useState } from "react";
import classNames from "classnames";
import Caret from "./Nav.caret";

const { all, showModels } = window?.enervent?.translations ?? {};

const NavFourthLevelMobile = ({ items, dispatch }) => {
  // Create a state to manage visibility of subcategories for each item
  const [visibleSubCats, setVisibleSubCats] = useState({});

  // Toggle visibility of subcategories for a given item
  const toggleVisibility = (name) => {
    setVisibleSubCats((prevVisibleSubCats) => ({
      ...prevVisibleSubCats,
      [name]: !prevVisibleSubCats[name],
    }));
  };

  return (
    <div className="nav__fourth-level nav__fourth-level--mobile">
      {items
        .sort((a, b) => parseInt(a.order || 0, 10) - parseInt(b.order || 0, 10))
        .map(({ link, toggle_subcats, name, children }) => (
          <div
            key={name}
            className="nav__fourth-level-oem nav__fourth-level-oem--mobile"
          >
            <div className="oem">
              <a href={link}>
                <div className="nav__fourth-level-oem-name nav__fourth-level-oem-name--mobile">
                  {toggle_subcats ? `${all} ` : ""}
                  {name}
                </div>
              </a>
              {toggle_subcats && (
                <div
                  className="nav__subcats"
                  onClick={() => toggleVisibility(name)}
                >
                  <p>{showModels}</p>
                  <div
                    className={classNames({
                      nav__caret: true,
                      "nav__caret--mobile": true,
                      "nav__caret--open": visibleSubCats[name],
                    })}
                  >
                    <Caret />
                  </div>
                </div>
              )}
            </div>
            {visibleSubCats[name] && (
              <div className="nav__fourth-level-items nav__fourth-level-items--mobile">
                {children &&
                  children
                    .sort(
                      (a, b) =>
                        parseInt(a.order || 0, 10) - parseInt(b.order || 0, 10)
                    )
                    .map((i) => (
                      <a key={i.name} href={i.link}>
                        <div className="nav__fourth-level-item nav__fourth-level-item--mobile">
                          <span className="nav__fourth-level-name">
                            {i.name}
                          </span>
                        </div>
                      </a>
                    ))}
              </div>
            )}
          </div>
        ))}
    </div>
  );
};

export default NavFourthLevelMobile;
