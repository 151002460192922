import { useState, useEffect, useRef } from "react";
import { clickOutside } from "./Nav/Nav.actions";
import { useDispatch } from "react-redux";

function useComponentVisible(initialIsVisible) {
  const dispatch = useDispatch();
  const [isComponentVisible, setIsComponentVisible] =
    useState(initialIsVisible);
  const ref = useRef(null);

  const handleHideDropdown = (event) => {
    if (event.key === "Escape") {
      setIsComponentVisible(false);
    }
  };

  const handleClickOutside = (event) => {
    if (event.srcElement.className === "nav__first-level-name") {
      return;
    }
    if (ref.current && !ref.current.contains(event.target)) {
      dispatch(clickOutside());
      setIsComponentVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleHideDropdown, true);
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("keydown", handleHideDropdown, true);
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  return { ref, isComponentVisible, setIsComponentVisible };
}

export default useComponentVisible;
