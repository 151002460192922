import React from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import * as selectors from "./Nav.selectors";
import ThirdLevelNavMobile from "../Nav/Nav.third.level.mobile";
import { clickSecondLevel } from "./Nav.actions";
import Caret from "./Nav.caret";

const SecondLevelNavMobile = ({
  items,
  secondLevel,
  thirdLevel,
  thirdLevelVisible,
  dispatch,
}) => (
  <div className="nav__second-level nav__second-level--mobile">
    {items
      .sort((a, b) => parseInt(a.order || 0, 10) - parseInt(b.order || 0, 10))
      .map((item) => (
        <>
          <div
            className={classNames({
              "nav__second-level-item": true,
              "nav__second-level-item--mobile": true,
              "nav__second-level-item--active": thirdLevel === item.name,
            })}
            onClick={() => dispatch(clickSecondLevel(item.name))}
          >
            <span
              className={classNames({
                "nav__second-level-name": true,
                "nav__second-level-name--active": thirdLevel === item.name,
              })}
            >
              {item.name}
            </span>
            <div
              className={classNames({
                nav__caret: true,
                "nav__caret--mobile": true,
                "nav__caret--open": thirdLevel === item.name,
              })}
            >
              <Caret />
            </div>
          </div>
          {thirdLevelVisible && thirdLevel === item.name && (
            <ThirdLevelNavMobile />
          )}
        </>
      ))}
  </div>
);

const mapStateToProps = (state) => ({
  thirdLevelVisible: selectors.thirdLevelVisible(state),
  secondLevel: selectors.secondLevel(state),
  thirdLevel: selectors.thirdLevel(state),
  items: selectors.secondLevelItems(state),
});

export default connect(mapStateToProps)(SecondLevelNavMobile);
