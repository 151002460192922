import React, { useState, useEffect } from "react";
import axios from "axios";
import Table from "./components/Offerlist/Table";
import Prices from "./components/Offerlist/Prices";
import ActionMenu from "./components/Offerlist/ActionMenu";
import CustomTable from "./components/Offerlist/CustomTable";
import { strings, localizations } from "./util/i18n";

function App() {
  // Assets from php
  const {
    pageId,
    companyVat,
    OfferReceiver,
    OfferDate,
    OfferTitle,
    OfferDescription,
    pageTitle,
    companyName,
    userName,
    userEmail,
    userPhone,
    isLogged,
    offerStatus,
    nonce,
  } = window?.enervent;

  // PRICES

  // List
  const [data, setData] = useState([]); // eslint-disable-line no-unused-vars
  const [rows, setRows] = useState([]);
  const [customRows, setCustomRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isAdding, setIsAdding] = useState(false); // eslint-disable-line no-unused-vars
  // Offer sent state
  const [isSent, setIsSending] = useState(false);
  // Custom comment
  const [comment, setComment] = useState("");

  // Description field
  const [description, setDescription] = useState(OfferDescription);
  const [title, setTitle] = useState(OfferTitle);
  const [inputField, setInputField] = useState(false);

  const createRowsFromList = (list) => {
    const keys = Object.keys(list?.offerlist_acf?.products);
    if (!keys) return;
    const rowPointer = list?.offerlist_acf?.products[keys[0]];
    const rowAmount = Array.isArray(rowPointer) ? rowPointer.length : 0;
    const rows = [];
    for (let i = 0; i < rowAmount; i++) {
      const row = {};
      keys.forEach((key) => {
        row[key] = list?.offerlist_acf?.products[key][i];
        row.index = i;
      });
      Object.keys(row).forEach((key) => {
        switch (key) {
          case "product_margin":
            row[key] = parseFloat(row[key]);
            break;
          case "product_amount":
            row[key] = parseInt(row[key], 10);
            break;
          case "product_discount":
            row[key] = parseInt(row[key], 10);
            break;
          case "product_id":
            row[key] = parseInt(row[key], 10);
            break;
          case "variation":
            row[key] = row[key] && JSON.parse(row[key]);
            break;
          case "product_buying_price":
            if (keys.includes("product_id")) {
              row[key] =
                (parseFloat(row[key]) * (100 - row["product_discount"])) / 100;
            } else {
              row[key] = parseFloat(row[key]);
            }
            break;
          case "product_original_price":
            row[key] = parseFloat(row[key]);
            break;
          case "product_selling_price":
            row[key] = parseFloat(row[key]);
            break;
          default:
            break;
        }
      });
      rows.push(row);
    }
    if (rows.length) {
      setRows(rows);
    }
  };

  const createCustomRowsFromList = (list) => {
    const keys = Object.keys(list?.offerlist_acf?.custom_rows);
    if (!keys) return;
    const rowPointer = list?.offerlist_acf?.custom_rows[keys[0]];
    const rowAmount = Array.isArray(rowPointer) ? rowPointer.length : 0;
    const rows = [];
    for (let i = 0; i < rowAmount; i++) {
      const row = {};
      keys.forEach((key) => {
        row[key] = list?.offerlist_acf?.custom_rows[key][i];
        row.index = i;
      });
      Object.keys(row).forEach((key) => {
        switch (key) {
          case "custom_amount":
            row[key] = parseInt(row[key], 10);
            break;
          case "custom_single_price":
            row[key] = parseFloat(row[key]);
            break;
          case "custom_vat_percentage":
            row[key] = parseFloat(row[key]);
            break;
          default:
            break;
        }
      });
      rows.push(row);
    }
    if (rows.length) {
      setCustomRows(rows);
    }
  };

  const fetchLists = async () => {
    try {
      setLoading(true);
      // TODO:
      // DO NOT FETCH ALL LISTS!!!!!!!!!!!
      const { data } = await axios("/wp-json/wp/v2/offerlists");

      const [list] = data.filter((list) => +list.id === +pageId);
      setData(list);
      createRowsFromList(list);
      createCustomRowsFromList(list);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLists();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const changeFields = () => {
    setInputField(!inputField);
  };

  const saveDescriptionAndTitleField = (id) => {
    const url = "/wp-json/wp/v2/offerlists/acf/" + id;
    const body = JSON.stringify({
      description: description,
      title: title,
    });
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-WP-Nonce": nonce,
      },
      body: body,
    });
    setInputField(!inputField);
  };

  const addCustomRow = () => {
    const index = customRows ? customRows.length : 0;
    const newRow = {
      index,
      custom_title: "",
      custom_amount: 1,
      custom_single_price: 0,
      custom_vat_percentage: parseInt(localizations.taxPercent),
    };
    setCustomRows([...customRows, newRow]);
  };

  const handleOfferSubmit = (pageId, result, comment) => {
    setIsSending(true);
    const url = "/wp-json/wp/v2/offerlists/email/" + pageId;
    const body = JSON.stringify({
      result: result,
      comment: comment,
    });

    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-WP-Nonce": nonce,
      },
      body: body,
    });
  };

  const showOfferButtons = !isSent ? (
    <div className="accept-refuse-offer">
      <button
        className="button green"
        onClick={() => handleOfferSubmit(pageId, true, comment)}
      >
        {strings.acceptOffer}
      </button>
      <button
        className="button ghost"
        onClick={() => handleOfferSubmit(pageId, false, comment)}
      >
        {strings.declineOffer}
      </button>
    </div>
  ) : (
    <div className="offerlist-email-sent">
      <p>{strings.confirmationMessage}</p>
    </div>
  );

  const showActionMenu =
    isLogged &&
    ((Array.isArray(rows) && !!rows.length) ||
      (Array.isArray(customRows) && !!customRows.length));

  const getOfferStatus = () => {
    switch (offerStatus) {
      case "accepted":
        return (
          <div className="accepted-offer">
            <p>{strings.offerApproved}</p>{" "}
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21.3744 4.45312H19.7361C19.5064 4.45312 19.2885 4.55859 19.1478 4.73906L9.48456 16.9805L4.85097 11.1094C4.78087 11.0204 4.69153 10.9484 4.58964 10.8989C4.48775 10.8494 4.37597 10.8236 4.26268 10.8234H2.6244C2.46737 10.8234 2.38065 11.0039 2.47675 11.1258L8.89628 19.2586C9.19628 19.6383 9.77284 19.6383 10.0752 19.2586L21.5221 4.75313C21.6182 4.63359 21.5314 4.45312 21.3744 4.45312Z"
                fill="white"
              />
            </svg>
          </div>
        );
      case "denied":
        return (
          <div className="denied-offer">
            <p>{strings.offerDenied}</p>
          </div>
        );
      case "archived":
        return (
          <div className="archived-offer">
            <p>{strings.offerArchived}</p>
          </div>
        );
      default:
        break;
    }
  };

  return (
    <>
      {showActionMenu && <ActionMenu rows={rows} />}
      {getOfferStatus()}
      <div
        className={
          offerStatus === "accepted" || offerStatus === "archived"
            ? "products-table accepted"
            : "products-table"
        }
      >
        <div className="new-list">
          <div className="new-list__form">
            <div className="new-list__form-content">
              <div className="new-list__upper">
                <span className="new-list__date">{OfferDate}</span>
                {isLogged ? (
                  <div className="pen" onClick={() => changeFields()}>
                    <img
                      alt="pen"
                      src="/wp-content/themes/enervent/dist/images/pen.svg"
                    />
                  </div>
                ) : null}
              </div>

              {inputField ? (
                <div>
                  <input
                    type="textarea"
                    value={title}
                    name="title"
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>
              ) : (
                <h1 className="new-list__green">{title}</h1>
              )}

              <p>
                {inputField ? (
                  <div>
                    <input
                      type="textarea"
                      value={description}
                      name="description"
                      onChange={(e) => setDescription(e.target.value)}
                    />
                    <button
                      className="green button"
                      onClick={() => saveDescriptionAndTitleField(pageId)}
                    >
                      {strings.offerSave}
                    </button>
                  </div>
                ) : (
                  description
                )}
              </p>
            </div>

            <Prices rows={rows} customRows={customRows} isLogged={isLogged} />

            {!isLogged
              ? offerStatus !== "accepted" && (
                  <div class="new-list__comment">
                    <label for="comment">{strings.additionalComment}</label>
                    <input
                      type="textarea"
                      value={comment}
                      name="comment"
                      onChange={(e) => setComment(e.target.value)}
                    />
                  </div>
                )
              : null}
            {!isLogged && offerStatus !== "accepted" ? showOfferButtons : null}
          </div>

          <div className="new-list__sender">
            <div class="target">
              <h3>{strings.target}</h3>
              <p>
                {pageTitle} <br />
                {OfferReceiver}
              </p>
            </div>

            <div class="sender">
              <h3>{strings.sender}</h3>
              <p>
                {companyName} <br />
                {strings.businessId} {companyVat}
              </p>
              <p>
                {userName}
                <br />
                {userEmail}
                <br />
                {userPhone}
              </p>
            </div>
          </div>
        </div>

        {!!Array.isArray(rows) && !!rows.length && (
          <Table
            isLogged={isLogged}
            loading={loading}
            pageId={pageId}
            rows={rows}
            setRows={setRows}
          />
        )}
        {isLogged && (
          <div class="add-products">
            <p>
              {strings.addProductToList}{" "}
              <a href={window?.enervent?.shopUrl}>{strings.productView}</a>{" "}
              {strings.linkToAdd}
            </p>
          </div>
        )}
        {!!Array.isArray(customRows) && !!customRows.length && (
          <CustomTable
            isLogged={isLogged}
            loading={loading}
            pageId={pageId}
            rows={customRows}
            setRows={setCustomRows}
          />
        )}
        {isLogged ? (
          <button class="button green" type="primary" onClick={addCustomRow}>
            <span class="add-to-list">{strings.addCustomRow}</span>
          </button>
        ) : null}
      </div>
      <div className="offerlist-bottom"></div>
    </>
  );
}

export default App;
