import React from "react";
import { connect } from "react-redux";
import FourthLevelNav from "../Nav/Nav.fourth.level";
import * as selectors from "./Nav.selectors";

const ThirdLevelNav = ({ items, categoryDescription }) => (
  <div className="nav__third-level nav__third-level--desktop">
    {categoryDescription && (
      <div
        className="nav__third-level nav__third-level--description"
        dangerouslySetInnerHTML={{ __html: categoryDescription }}
      />
    )}
    {items[0]?.children && <FourthLevelNav items={items} />}
    {!items[0]?.children &&
      items
        .sort((a, b) => parseInt(a.order || 0, 10) - parseInt(b.order || 0, 10))
        .map((item) => (
          <a href={item.link}>
            <div className="nav__third-level-item nav__third-level-item--desktop">
              <span className="nav__third-level-name">{item.name}</span>
            </div>
          </a>
        ))}
  </div>
);

const mapStateToProps = (state) => ({
  items: selectors?.thirdLevelItems(state),
  categoryDescription: selectors.categoryDescription(state),
});

export default connect(mapStateToProps)(ThirdLevelNav);
