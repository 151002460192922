import { createAction } from "../../store";

export const CLICK_NAV = "CLICK_NAV";
export const CLICK_AUX_NAV = "CLICK_AUX_NAV";
export const CLICK_SEARCH = "CLICK_SEARCH";
export const CLICK_FIRST_LEVEL = "CLICK_FIRST_LEVEL";
export const CLICK_OUTSIDE = "CLICK_OUTSIDE";
export const CLICK_SECOND_LEVEL = "CLICK_SECOND_LEVEL";
export const FETCH_NAV = "FETCH_NAV";
export const SET_AUX_NAV = "SET_AUX_NAV";
export const SET_NAV = "SET_NAV";
export const SET_NAV_LOADED = "SET_NAV_LOADED";
export const SET_NAV_OPEN = "SET_NAV_OPEN";
export const SET_SEARCH_OPEN = "SET_SEARCH_OPEN";
export const SET_SECOND_LEVEL = "SET_SECOND_LEVEL";
export const SET_SECOND_LEVEL_VISIBLE = "SET_SECOND_LEVEL_VISIBLE";
export const SET_THIRD_LEVEL = "SET_THIRD_LEVEL";
export const SET_THIRD_LEVEL_VISIBLE = "SET_THIRD_LEVEL_VISIBLE";
export const SET_CATEGORY_DESCRIPTION = "SET_CATEGORY_DESCRIPTION";

export const clickNav = () => createAction(CLICK_NAV);
export const clickAuxNav = () => createAction(CLICK_AUX_NAV);
export const clickSearch = () => createAction(CLICK_SEARCH);
export const fetchNav = (menuId) => createAction(FETCH_NAV, menuId);
export const setNav = (data) => createAction(SET_NAV, data);
export const setAuxNav = (data) => createAction(SET_AUX_NAV, data);
export const setNavLoaded = (payload) => createAction(SET_NAV_LOADED, payload);
export const setNavOpen = (payload) => createAction(SET_NAV_OPEN, payload);
export const setSearchOpen = (payload) =>
  createAction(SET_SEARCH_OPEN, payload);
export const setCategoryDescription = (payload) =>
  createAction(SET_CATEGORY_DESCRIPTION, payload);
export const clickFirstLevel = (name) => createAction(CLICK_FIRST_LEVEL, name);
export const clickOutside = (payload) => createAction(CLICK_OUTSIDE, payload);
export const clickSecondLevel = (name) =>
  createAction(CLICK_SECOND_LEVEL, name);
export const setSecondLevel = (name) => createAction(SET_SECOND_LEVEL, name);
export const setSecondLevelVisible = (payload) =>
  createAction(SET_SECOND_LEVEL_VISIBLE, payload);
export const setThirdLevel = (name) => createAction(SET_THIRD_LEVEL, name);
export const setThirdLevelVisible = (payload) =>
  createAction(SET_THIRD_LEVEL_VISIBLE, payload);
