import React from "react";
import ReactDOM from "react-dom";
import NavApp from "./NavApp";
import OfferlistOfferApp from "./OfferlistOfferApp";
import OfferlistListApp from "./OfferlistListApp";

const navContainer = document.getElementById("main-header-container");
const offerlistOfferContainer = document.getElementById("table-products");
const offerlistModalContainer = document.getElementById("offerlist-container");
const offerlistAccountListApp = document.getElementById(
  "offerlists-account-view"
);
const offerlistHeaderApp = document.getElementById("offerlists-header");

if (offerlistAccountListApp) {
  ReactDOM.render(
    <OfferlistListApp onlyList={true} showLogo={true} />,
    offerlistAccountListApp
  );
}
if (offerlistHeaderApp) {
  ReactDOM.render(<OfferlistListApp onlyList={true} />, offerlistHeaderApp);
}
if (navContainer) {
  ReactDOM.render(<NavApp />, navContainer);
}
if (offerlistOfferContainer) {
  ReactDOM.render(
    <React.StrictMode>
      <OfferlistOfferApp />
    </React.StrictMode>,
    offerlistOfferContainer
  );
}
if (offerlistModalContainer) {
  ReactDOM.render(
    <OfferlistListApp modalApp={true} />,
    offerlistModalContainer
  );
}
