import React from "react";
import TableRow from "./TableRow";
import { strings } from "../../util/i18n";

function Table({ isLogged, loading, pageId, rows, setRows }) {
  return loading ? (
    <div className="loading">
      <img
        alt="spinner"
        className="gif-image"
        src="/wp-content/themes/enervent/dist/images/spinner.gif"
      />
    </div>
  ) : (
    <>
      <div className="table-description">
        <span>{strings.enerventBreakDown}</span>
        <span className="greyspan">{strings.pricesBeforeTax}</span>
      </div>
      <table className="products">
        <thead>
          <tr>
            {isLogged ? (
              <>
                <th scope="col" className="col--sku">
                  {strings.productNumber}
                </th>
                <th scope="col" className="col--name">
                  {strings.productName}
                </th>
                <th scope="col" className="col--amount">
                  {strings.productAmount}
                </th>
                <th scope="col" className="col--cost">{`${
                  strings.purchasePrice
                }${"\u00A0"}á*`}</th>
                <th scope="col" className="col--margin">
                  {strings.margin} (%)
                </th>
                <th scope="col" className="col--price">{`${
                  strings.sellingPrice
                }${"\u00A0"}á*`}</th>
                <th scope="col" className="col--x"></th>
              </>
            ) : (
              <>
                <th scope="col" className="col--sku">
                  {strings.productNumber}
                </th>
                <th scope="col" className="col--name">
                  {strings.productName}
                </th>
                <th scope="col" className="col--amount">
                  {strings.productAmount}
                </th>
                <th scope="col" className="col--price">{`${
                  strings.price
                }${"\u00A0"}á*`}</th>
                <th scope="col" className="col--vat">
                  {strings.vatPercentage}
                </th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {rows.length > 0
            ? rows.map((row) => (
                <TableRow
                  row={row}
                  rows={rows}
                  setRows={setRows}
                  pageId={pageId}
                  isLogged={isLogged}
                />
              ))
            : null}
        </tbody>
      </table>
    </>
  );
}
export default Table;
