import React from "react";
import { connect } from "react-redux";
import SearchOutlined from "@ant-design/icons/SearchOutlined";
import classNames from "classnames";
import * as selectors from "./Nav.selectors";
import { clickFirstLevel, clickSearch } from "./Nav.actions";
import Caret from "./Nav.caret";

const FirstLevelNav = ({ items, dispatch, secondLevel }) => (
  <div className="nav__first-level">
    {items &&
      items
        .sort((a, b) => parseInt(a.order || 0, 10) - parseInt(b.order || 0, 10))
        .map((item) => (
          <div
            className={classNames({
              "nav__first-level-item": true,
              "nav__first-level-item--desktop": true,
              "nav__first-level-item--active": secondLevel === item.name,
            })}
            onClick={() => dispatch(clickFirstLevel(item.name))}
          >
            <span className="nav__first-level-name">{item.name}</span>
            <span
              className={classNames({
                "nav__first-level-caret": true,
                "nav__first-level-caret--active": secondLevel === item.name,
              })}
            >
              <Caret />
            </span>
          </div>
        ))}
    <div
      className={classNames({
        "nav__first-level-item": true,
        "nav__first-level-item-search": true,
        "nav__first-level-item--desktop": true,
      })}
      onClick={() => dispatch(clickSearch())}
    >
      <span>
        <SearchOutlined />
      </span>
    </div>
  </div>
);

const mapStateToProps = (state) => ({
  items: selectors.firstLevelItems(state),
  secondLevel: selectors.secondLevel(state),
});

export default connect(mapStateToProps)(FirstLevelNav);
