import React, { useState, useRef, useLayoutEffect } from "react";
import u from "updeep";
import { DebounceInput } from "react-debounce-input";
import { strings, localizations } from "../../util/i18n";

function TableRow({ pageId, rows, row, setRows, isLogged }) {
  const [modifyTitle, setModifyTitle] = useState(false);
  const [modifyPrice, setModifyPrice] = useState(false);
  const [modifyTax, setModifyTax] = useState(false);

  const firstUpdate = useRef(true);
  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    const url = `/wp-json/wp/v2/offerlists/${pageId}/${row.index}`;
    const body = JSON.stringify({
      custom: true,
      custom_title: row.custom_title,
      custom_single_price: +row.custom_single_price,
      custom_vat_percentage: +row.custom_vat_percentage,
      custom_amount: +row.custom_amount,
    });
    try {
      fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-WP-Nonce": window?.enervent?.nonce,
        },
        body: body,
      });
    } catch (err) {
      console.error(err);
    }
  }, [row, pageId]);

  const deleteRow = () => {
    const newRows = rows.filter(({ index }) => index !== row.index);
    setRows(newRows);
    const url = `/wp-json/wp/v2/offerlists/${pageId}/${row.index}?custom=true`;
    try {
      fetch(url, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-WP-Nonce": window?.enervent?.nonce,
        },
      });
    } catch (err) {
      console.error(err);
    }
  };

  const increaseAmount = () => {
    const newRows = u(
      { [row.index]: { custom_amount: row.custom_amount + 1 } },
      rows
    );
    setRows(newRows);
  };

  const decreaseAmount = () => {
    const newRows = u(
      {
        [row.index]: { custom_amount: Math.max(1, row.custom_amount - 1) },
      },
      rows
    );
    setRows(newRows);
  };

  const updateSinglePrice = (price) => {
    const newRows = u(
      {
        [row.index]: {
          custom_single_price: parseFloat(price),
        },
      },
      rows
    );
    setRows(newRows);
  };

  const updateCustomTitle = (title) => {
    const newRows = u(
      {
        [row.index]: {
          custom_title: title,
        },
      },
      rows
    );
    setRows(newRows);
  };

  const updateTaxPercentage = (percentage) => {
    const newRows = u(
      {
        [row.index]: {
          custom_vat_percentage: parseFloat(percentage),
        },
      },
      rows
    );
    setRows(newRows);
  };

  if (!isLogged) {
    return (
      <tr>
        <td></td>
        <td data-label={strings.productName}>{row.custom_title}</td>
        <td data-label={strings.productAmount}>{row.custom_amount}</td>
        <td className="pre" data-label={strings.price}>
          {isNaN(row.custom_single_price)
            ? 0
            : row.custom_single_price.toFixed(2)}{" "}
          {localizations.offerCurrency}
        </td>
        <td className="pre" data-label={strings.vatPercentage}>
          {row.custom_vat_percentage}
        </td>
      </tr>
    );
  }

  return (
    <tr>
      {modifyTitle && (
        <td data-label={strings.productName}>
          <DebounceInput
            element="textarea"
            type="text"
            debounceTimeout={100}
            name="custom_title"
            onChange={(e) => {
              updateCustomTitle(e.target.value);
            }}
            value={row.custom_title}
          />
          <span
            className="edit"
            onClick={() => {
              setModifyTitle(!modifyTitle);
            }}
          >
            Tallenna
          </span>
        </td>
      )}
      {!modifyTitle && (
        <td data-label={strings.productName}>
          {row.custom_title}
          <span
            className="edit"
            onClick={() => {
              setModifyTitle(!modifyTitle);
            }}
          >
            Muokkaa
          </span>
        </td>
      )}
      <td className="inputs" data-label={strings.productAmount}>
        <span class="incdec" onClick={decreaseAmount}>
          -
        </span>
        <DebounceInput
          name="custom_amount"
          type="number"
          min="1"
          debounceTimeout={100}
          value={row.custom_amount}
          disabled
        />
        <span class="incdec" onClick={increaseAmount}>
          +
        </span>
      </td>

      {modifyPrice && (
        <td data-label={strings.sellingPrice}>
          <DebounceInput
            type="number"
            min="0"
            debounceTimeout={100}
            name="custom_single_price"
            onChange={(e) => {
              updateSinglePrice(parseFloat(e.target.value));
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                updateSinglePrice(parseFloat(e.target.value));
                setModifyPrice(false);
              }
            }}
            value={row.custom_single_price}
          />
          <span
            className="edit"
            onClick={() => {
              setModifyPrice(!modifyPrice);
            }}
          >
            Tallenna
          </span>
        </td>
      )}
      {!modifyPrice && (
        <td data-label={strings.sellingPrice}>
          <p className="pre">
            {" "}
            {row.custom_single_price &&
            row.custom_single_price > 0 &&
            isFinite(row.custom_single_price)
              ? row.custom_single_price.toFixed(2) +
                " " +
                localizations.offerCurrency
              : null}
            <span className="edit" onClick={() => setModifyPrice(!modifyPrice)}>
              Muokkaa
            </span>
          </p>
        </td>
      )}
      {modifyTax && (
        <td data-label={strings.vatPercentage}>
          <DebounceInput
            type="number"
            min="0"
            max="100"
            debounceTimeout={100}
            className={row.custom_vat_percentage < 0 ? "alert" : null}
            name="custom_vat_percentage"
            onChange={(e) => {
              updateTaxPercentage(parseFloat(e.target.value));
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                updateTaxPercentage(parseFloat(e.target.value));
                setModifyTax(false);
              }
            }}
            value={row.custom_vat_percentage}
          />
          %{" "}
          {100 < row.custom_vat_percentage < 0 ? (
            <p className="error">Numeron pitää olla 0 ja 100 väliltä</p>
          ) : null}
          <span
            className="edit"
            onClick={() => {
              setModifyTax(!modifyTax);
            }}
          >
            Tallenna
          </span>
        </td>
      )}
      {!modifyTax && (
        <td data-label={strings.vatPercentage}>
          <p className="pre">
            {" "}
            {row.custom_vat_percentage &&
            row.custom_vat_percentage > 0 &&
            isFinite(row.custom_vat_percentage)
              ? row.custom_vat_percentage.toFixed(2) + " %"
              : null}
            <span className="edit" onClick={() => setModifyTax(!modifyTax)}>
              Muokkaa
            </span>
          </p>
        </td>
      )}

      {isLogged && (
        <td className="delete-row" onClick={() => deleteRow()}>
          {" "}
          <img
            alt="x"
            src="/wp-content/themes/enervent/dist/images/x_red.svg"
          />{" "}
        </td>
      )}
    </tr>
  );
}

export default TableRow;
