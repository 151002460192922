import React, { useState } from "react";
import { strings } from "../../util/i18n";

function ModalSingleItem({ offerlist, onlyList }) {
  const { nonce, regularPrice, productTitle, discountText, productId } =
    window.enervent;
  const [isAdding, setIsAdding] = useState(false);

  if (onlyList === true) {
    return (
      <div>
        <div className="listed-offerlists__single">
          <a href={offerlist.guid}>
            <span className="single-offer__name">{offerlist.post_title}</span>
          </a>
        </div>
      </div>
    );
  }

  let variation = "";
  let variationId = "";
  const variationsSelect = document.querySelectorAll("table.variations select");
  if (variationsSelect) {
    const keys = Array.from(variationsSelect).map((el) => el.id);
    const keyValues = keys.map((key) => ({
      attribute: key,
      value: document.querySelector(`#${key} option:checked`).innerHTML,
    }));

    variation = JSON.stringify(keyValues);
    variationId = document.querySelector(".variation_id")?.value;
  }

  const name = productTitle;
  const price = regularPrice;

  const saveOfferlist = (id) => {
    const productQuantity = document.querySelector(".quantity input").value;
    const sku = document.querySelector(".sku").innerHTML;
    if (sku) {
      setIsAdding(true);

      const url = "/wp-json/wp/v2/offerlists/" + id;
      const body = JSON.stringify({
        product_number: sku,
        product_title: name,
        product_original_price: price,
        product_buying_price: price,
        product_amount: productQuantity,
        product_discount_text: discountText,
        product_id: productId,
        variation,
        variation_id: variationId,
      });

      fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-WP-Nonce": nonce,
        },
        body: body,
      });
      setTimeout(() => setIsAdding(false), 3000);
    }
  };

  return (
    <div>
      {isAdding ? (
        <div className="success-text">
          <p>{strings.productAdded} </p>
        </div>
      ) : null}
      <div
        className={
          isAdding
            ? "listed-offerlists__single green"
            : "listed-offerlists__single"
        }
      >
        <a href={offerlist.guid}>
          <span
            className={
              isAdding ? "single-offer__name white" : "single-offer__name"
            }
          >
            {offerlist.post_title}
          </span>
        </a>
        {isAdding ? (
          <span className="single-offer__checkmark"></span>
        ) : (
          <button
            class="button green"
            type="primary"
            onClick={() => saveOfferlist(offerlist.ID)}
          >
            <span class="add-to-list" key={offerlist.ID}>
              {strings.addProduct}
            </span>
          </button>
        )}
      </div>
    </div>
  );
}

export default ModalSingleItem;
