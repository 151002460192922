import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from "./components/Offerlist/Modal";
import { strings } from "./util/i18n";
import { Button } from "antd";
import { CheckOutlined } from "@ant-design/icons";

function App({ showLogo, onlyList, modalApp }) {
  const {
    companyVatOfferlist,
    nonce,
    createOfferList,
    archiveOfferList,
    offerLogo,
  } = window.enervent;

  const URL = "/wp-json/wp/v2/offerlists/" + companyVatOfferlist;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [show, setShow] = useState(false);
  const closeModal = () => setShow(false);
  const [image, setImage] = useState({ preview: "", data: "" });
  const [logo, setLogo] = useState(offerLogo);
  const [status, setStatus] = useState("");

  useEffect(() => {
    try {
      async function fetchData() {
        setLoading(true);
        const result = await axios(URL, {
          headers: { "X-WP-Nonce": nonce },
        });
        setData(result.data);
      }
      fetchData();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Get logo details (FILE), send data to REST api
  const handleSubmit = async (e) => {
    setStatus("loading");
    e.preventDefault();
    let formData = new FormData();
    formData.append("file", image.data);
    const url = "/wp-json/wp/v2/offerlists/upload";
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "X-WP-Nonce": nonce,
      },
      body: formData,
    });
    if (response) setStatus(response.statusText);
  };

  // Handle current file and set data accordingly
  const handleFileChange = (e) => {
    if (e.target.files[0].size > 5000000) {
      setStatus(strings.imageTooBig);
    } else {
      setStatus("");
      const img = {
        preview: window.URL.createObjectURL(e.target.files[0]),
        data: e.target.files[0],
      };
      setImage(img);
    }
  };

  // Delete logo
  const deleteLogo = async (e) => {
    setLogo(null);
    e.preventDefault();
    const img = {
      preview: "",
      data: "",
    };
    setImage(img);

    const url = "/wp-json/wp/v2/offerlists/delete-logo";
    await fetch(url, {
      method: "POST",
      headers: {
        "X-WP-Nonce": nonce,
      },
    });
  };

  // Handle file upload and show original state
  const isFileUploading = () =>
    status === "loading" ? (
      <Button type="primary" ghost loading>
        {strings.addingLogo}
      </Button>
    ) : (
      <Button type="primary" ghost htmlType="submit">
        {strings.saveLogo}
      </Button>
    );

  // Check if file is already saved or still in initial status
  const checkFileStatus = () =>
    status === "OK" ? (
      <Button type="primary" ghost htmlType="submit">
        {strings.logoSaved} <CheckOutlined />
      </Button>
    ) : (
      isFileUploading()
    );

  const className = modalApp ? "button ghost addlist" : "button gray-noshadow";
  return (
    <>
      <div className="offerlist">
        <button className={className} onClick={() => setShow(!show)}>
          {!!modalApp ? strings.addToList : strings.allLists}
        </button>
        <Modal
          onlyList={onlyList}
          closeModal={closeModal}
          show={show}
          offerlists={data}
          loading={loading}
          link={createOfferList}
          archives={archiveOfferList}
        />
      </div>

      {showLogo && (
        <div className="offerlist__logo-upload">
          <p>{strings.logoText}</p>
          {logo && image.preview === "" && (
            <img src={logo} alt="preview" width="100" height="auto" />
          )}
          {image.preview && (
            <img src={image.preview} alt="preview" width="100" height="auto" />
          )}

          <form onSubmit={handleSubmit}>
            <input
              type="file"
              id="file"
              name="file"
              onChange={handleFileChange}
              accept="image/gif, image/jpeg, image/png"
            />
            <label for="file">{strings.chooseLogo}</label>

            <div className="offerlist__logo-upload-buttons">
              {(logo || (image.preview !== "" && image.data !== "")) &&
                checkFileStatus()}

              {(logo || image.preview !== "" || image.data !== "") && (
                <Button danger type="primary" ghost onClick={deleteLogo}>
                  {strings.deleteLogo}
                </Button>
              )}
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default App;
