import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import MediaQuery from "react-responsive";
import { fetchNav } from "../Nav/Nav.actions";
import HeaderLeft from "./Header.left";
import HeaderTop from "./Header.top";
import Search from "./Header.search";
import FirstLevelNav from "../Nav/Nav.first.level";
import FirstLevelNavMobile from "../Nav/Nav.first.level.mobile";
import SecondLevelNav from "../Nav/Nav.second.level";
import AuxNav from "../Nav/Nav.aux";
import SecondLevelSearch from "../Header/Header.search.second.level";
import * as selectors from "../Nav/Nav.selectors";

const Header = () => {
  const secondLevelVisible = useSelector(selectors.secondLevelVisible);
  const searchOpen = useSelector(selectors.searchOpen);
  const navLoaded = useSelector(selectors.navLoaded);
  const navOpen = useSelector(selectors.navOpen);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchNav());
  });

  return (
    <>
      {/* Desktop header */}
      <MediaQuery minWidth={992}>
        <header className="main-header">
          <HeaderLeft />
          <div className="main-header__right">
            {navLoaded && <AuxNav />}
            {navLoaded && <FirstLevelNav />}
          </div>
        </header>
        {secondLevelVisible && <SecondLevelNav />}
        {searchOpen && <SecondLevelSearch />}
      </MediaQuery>
      {/* Mobile header */}
      <MediaQuery maxWidth={992}>
        <>
          <header className="main-header main-header--mobile">
            <HeaderTop />
            <div className="main-header__bottom">
              <Search location="mobile" />
            </div>
          </header>
          {navLoaded && navOpen && <FirstLevelNavMobile />}
        </>
      </MediaQuery>
    </>
  );
};

export default Header;
