import React, { useState } from "react";
import SearchOutlined from "@ant-design/icons/SearchOutlined";
import classNames from "classnames";

const { shopUrl } = window.enervent;
const { frontPage } = window.enervent;

const Search = ({ location }) => {
  const [input, setInput] = useState();
  const submit = () => (window.location.href = `${shopUrl}?s=${input}`);
  return (
    <div
      className={classNames(
        "main-header__search",
        `main-header__search--${location}`
      )}
    >
      <input
        autoFocus
        value={input}
        name="s"
        type="text"
        placeholder=""
        className={classNames(
          "main-header__search-input",
          `main-header__search-input--${location}`
        )}
        onChange={(e) => setInput(e.target.value)}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            submit();
          }
        }}
      />
      <div className="main-header__search-icon" onClick={() => submit()}>
        <SearchOutlined />
      </div>
    </div>
  );
};

export default Search;
