import { put, takeEvery, select, call } from "redux-saga/effects";
import * as selectors from "./Nav.selectors";
import {
  CLICK_FIRST_LEVEL,
  CLICK_NAV,
  CLICK_OUTSIDE,
  CLICK_SEARCH,
  CLICK_SECOND_LEVEL,
  FETCH_NAV,
  setCategoryDescription,
  setAuxNav,
  setNav,
  setNavLoaded,
  setNavOpen,
  setSearchOpen,
  setSecondLevel,
  setSecondLevelVisible,
  setThirdLevel,
  setThirdLevelVisible,
} from "./Nav.actions";

function* handleSearchClick() {
  const navOpen = yield select(selectors.navOpen);
  if (navOpen) {
    yield put(setNavOpen(false));
  }
  const searchOpen = yield select(selectors.searchOpen);
  if (searchOpen) {
    yield put(setSearchOpen(false));
  } else {
    yield put(setSearchOpen(true));
  }
}

function* handleNavClick() {
  const navOpen = yield select(selectors.navOpen);
  const searchOpen = yield select(selectors.searchOpen);
  if (searchOpen) {
    yield put(setSearchOpen(false));
  }
  if (navOpen) {
    yield call(closeMenu);
  } else {
    yield put(setNavOpen(true));
  }
}

function* fetchNavSaga() {
  // const response = yield axios.get(`/wp-json/gage/nav?lang=${enerventNav.lang}`)
  // if (response.status !== 200) {
  // return
  // }
  // const { data } = response
  // eslint-disable-next-line
  const primaryNav = enerventNav?.navHierarchy;
  // eslint-disable-next-line
  const auxNav = enerventNav?.auxNav;
  yield put(setNav(primaryNav));
  yield put(setAuxNav(auxNav));
  yield put(setNavLoaded(true));
}

function* handleFirstLevelClick({ payload }) {
  const secondLevelVisible = yield select(selectors.secondLevelVisible);
  const secondLevel = yield select(selectors.secondLevel);
  yield put(setSearchOpen(false));
  yield put(setThirdLevelVisible(false));
  yield put(setThirdLevel(""));
  yield put(setSecondLevel(payload));
  if (!secondLevelVisible) {
    yield put(setSecondLevelVisible(true));

    // Disable scrolling when menu open
    // document.body.style.overflow = "hidden";
  } else if (payload === secondLevel) {
    // document.body.style.overflow = "initial";
    yield put(setSecondLevelVisible(false));
    yield put(setSecondLevel(""));
  }
}

function* closeMenu() {
  // Add scrolling
  // document.body.style.overflow = "initial";

  yield put(setNavOpen(false));
  yield put(setSearchOpen(false));
  yield put(setThirdLevel(""));
  yield put(setThirdLevelVisible(true));
  yield put(setSecondLevel(""));
  yield put(setSecondLevelVisible(false));
}

function* handleSecondLevelClick({ payload }) {
  const thirdLevelVisible = yield select(selectors.thirdLevelVisible);
  const thirdLevel = yield select(selectors.thirdLevel);
  yield put(setThirdLevel(payload));
  if (!thirdLevelVisible) {
    yield put(setThirdLevelVisible(true));
  } else if (payload === thirdLevel) {
    yield put(setThirdLevelVisible(false));
    yield put(setThirdLevel(""));
  }
}

function* handleSetDescription({ payload }) {
  yield put(setCategoryDescription(payload));
}

export default function* saga() {
  yield takeEvery(CLICK_NAV, handleNavClick);
  yield takeEvery(FETCH_NAV, fetchNavSaga);
  yield takeEvery(CLICK_SEARCH, handleSearchClick);
  yield takeEvery(CLICK_FIRST_LEVEL, handleFirstLevelClick);
  yield takeEvery(CLICK_SECOND_LEVEL, handleSecondLevelClick);
  yield takeEvery(CLICK_SECOND_LEVEL, handleSetDescription);
  yield takeEvery(CLICK_OUTSIDE, closeMenu);
}
