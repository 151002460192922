import React, { useState, useRef, useLayoutEffect } from "react";
import u from "updeep";
import { DebounceInput } from "react-debounce-input";
import { strings, localizations } from "../../util/i18n";

function TableRow({ pageId, rows, row, setRows, isLogged }) {
  const [modifyMargin, setModifyMargin] = useState(false);
  const [modifyPrice, setModifyPrice] = useState(false);

  const firstUpdate = useRef(true);
  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    const url = `/wp-json/wp/v2/offerlists/${pageId}/${row.index}`;
    const body = JSON.stringify({
      custom: false,
      product_buying_price: +row.product_buying_price,
      product_selling_price: +row.product_selling_price,
      product_margin: +row.product_margin,
      product_amount: +row.product_amount,
    });
    try {
      fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-WP-Nonce": window?.enervent?.nonce,
        },
        body: body,
      });
    } catch (err) {
      console.error(err);
    }
  }, [row, pageId]);

  const deleteRow = () => {
    const newRows = rows.filter(({ index }) => index !== row.index);
    setRows(newRows);
    const url = `/wp-json/wp/v2/offerlists/${pageId}/${row.index}`;
    try {
      fetch(url, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-WP-Nonce": window?.enervent?.nonce,
        },
      });
    } catch (err) {
      console.error(err);
    }
  };

  const increaseAmount = () => {
    const newRows = u(
      { [row.index]: { product_amount: row.product_amount + 1 } },
      rows
    );
    setRows(newRows);
  };

  const decreaseAmount = () => {
    const newRows = u(
      {
        [row.index]: { product_amount: Math.max(1, row.product_amount - 1) },
      },
      rows
    );
    setRows(newRows);
  };

  const updateMargin = (margin) => {
    const newRows = u(
      {
        [row.index]: {
          product_margin: parseFloat(margin),
          product_selling_price:
            row.product_buying_price +
            row.product_buying_price * ((100 + parseFloat(margin)) / 100 - 1),
        },
      },
      rows
    );
    setRows(newRows);
  };

  const updateSellingPrice = (price) => {
    const newRows = u(
      {
        [row.index]: {
          product_selling_price: parseFloat(price),
          product_margin:
            (parseFloat(price) / row.product_buying_price - 1) * 100,
        },
      },
      rows
    );
    setRows(newRows);
  };

  return (
    <>
      {!isLogged && (
        <tr>
          <td data-label={strings.productNumber}>
            <p>{row.product_number}</p>
          </td>
          <td data-label={strings.productName}>
            <p>{row.product_title}</p>
            {Array.isArray(row?.variation) &&
              row.variation.map((variation) => (
                <span className="offerlist-variation"> {variation.value} </span>
              ))}
          </td>
          <td className="pre" data-label={strings.productAmount}>
            <p>{row.product_amount}</p>
          </td>
          <td className="pre" data-label={strings.price}>
            <p>
              {isNaN(row.product_selling_price)
                ? 0
                : row.product_selling_price.toFixed(2)}{" "}
              {localizations.offerCurrency}
            </p>
          </td>
          <td className="pre">
            <p>{localizations.taxPercent}</p>
          </td>
        </tr>
      )}
      {isLogged && (
        <tr>
          <td data-label={strings.productNumber}>{row.product_number}</td>
          <td data-label={strings.productName}>
            {row.product_title}
            {!!row.product_discount && "*"}

            {Array.isArray(row?.variation) &&
              row.variation.map((variation) => (
                <span className="offerlist-variation"> {variation.value} </span>
              ))}

            <br />
            <br />
            {!!row.product_discount &&
              `${strings.discountText} ${row.product_discount} %`}
          </td>
          <td className="inputs" data-label={strings.productAmount}>
            <span class="incdec" onClick={decreaseAmount}>
              -
            </span>
            <DebounceInput
              name="product_amount"
              type="number"
              min={1}
              debounceTimeout={100}
              value={row.product_amount}
              disabled
            />
            <span class="incdec" onClick={increaseAmount}>
              +
            </span>
          </td>
          <td data-label={strings.buyingPrice}>
            <p>
              {isNaN(row.product_buying_price)
                ? 0
                : row.product_buying_price.toFixed(2)}{" "}
              {localizations.offerCurrency}
            </p>
          </td>
          {/*
          <td class="margin-input" data-label={strings.margin}>
            {row.product_margin > -1 && isFinite(row.product_margin)
              ? row.product_margin.toFixed(2) + " %"
              : "-"}
          </td>
          */}
          {modifyMargin && (
            <td data-label={strings.margin}>
              <DebounceInput
                type="number"
                min={0}
                debounceTimeout={100}
                className={row.product_margin < 0 ? "alert" : null}
                name="product_margin"
                onChange={(e) => {
                  updateMargin(parseFloat(e.target.value));
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    updateMargin(parseFloat(e.target.value));
                    setModifyMargin(false);
                  }
                }}
                value={row.product_margin}
              />
              %{" "}
              {row.product_margin < 0 ? (
                <p className="error">Numeron pitää olla yli 0</p>
              ) : null}
              <span
                className="edit"
                onClick={() => {
                  setModifyMargin(!modifyMargin);
                }}
              >
                Tallenna
              </span>
            </td>
          )}
          {!modifyMargin && (
            <td class="margin-input" data-label={strings.margin}>
              <p className="pre">
                {row.product_margin &&
                row.product_margin >= 0 &&
                isFinite(row.product_margin)
                  ? row.product_margin.toFixed(2) + " %"
                  : null}
                <span
                  className="edit"
                  onClick={() => setModifyMargin(!modifyMargin)}
                >
                  Muokkaa
                </span>
              </p>
            </td>
          )}

          {modifyPrice && (
            <td data-label={strings.sellingPrice}>
              <DebounceInput
                type="number"
                min={0}
                debounceTimeout={100}
                name="product_price"
                onChange={(e) => {
                  updateSellingPrice(parseFloat(e.target.value));
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    updateSellingPrice(parseFloat(e.target.value));
                    setModifyPrice(false);
                  }
                }}
                value={row.product_selling_price}
              />
              <span
                className="edit"
                onClick={() => {
                  setModifyPrice(!modifyPrice);
                }}
              >
                Tallenna
              </span>
            </td>
          )}
          {!modifyPrice && (
            <td data-label={strings.sellingPrice}>
              <p className="pre">
                {" "}
                {row.product_selling_price &&
                row.product_selling_price > 0 &&
                isFinite(row.product_selling_price)
                  ? row.product_selling_price.toFixed(2) +
                    localizations.offerCurrency
                  : null}
                <span
                  className="edit"
                  onClick={() => setModifyPrice(!modifyPrice)}
                >
                  Muokkaa
                </span>
              </p>
            </td>
          )}

          <td className="delete-row" onClick={() => deleteRow()}>
            {" "}
            <img
              alt="x"
              src="/wp-content/themes/enervent/dist/images/x_red.svg"
            />{" "}
          </td>
        </tr>
      )}
    </>
  );
}

export default TableRow;
