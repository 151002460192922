import React from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import * as selectors from "./Nav.selectors";
import ThirdLevelNav from "../Nav/Nav.third.level";
import { clickSecondLevel, setCategoryDescription } from "./Nav.actions";
import useComponentVisible from "../useComponentVisible";

const SecondLevelNav = ({
  items,
  secondLevel,
  thirdLevel,
  thirdLevelVisible,
  dispatch,
}) => {
  const { ref, isComponentVisible } = useComponentVisible(true);

  return (
    <div
      className="nav__second-level-container nav__second-level-container--desktop"
      ref={ref}
    >
      {isComponentVisible && (
        <div className="nav__second-level nav__second-level--desktop">
          <h2 className="nav__second-level-heading">{secondLevel}</h2>
          <div className="nav__second-level-content">
            <div className="nav__second-level-left">
              {items
                .sort(
                  (a, b) =>
                    parseInt(a.order || 0, 10) - parseInt(b.order || 0, 10)
                )
                .map((item) => (
                  <div
                    className={classNames({
                      "nav__second-level-item": true,
                      "nav__second-level-item--active":
                        thirdLevel === item.name,
                    })}
                    onClick={() => {
                      dispatch(clickSecondLevel(item.name));
                      dispatch(setCategoryDescription(item.description));
                    }}
                  >
                    <span className="nav__second-level-name">{item.name}</span>
                  </div>
                ))}
            </div>
            <div className="nav__second-level-right">
              {thirdLevelVisible && <ThirdLevelNav />}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  thirdLevelVisible: selectors.thirdLevelVisible(state),
  secondLevelVisible: selectors.secondLevelVisible(state),
  secondLevel: selectors.secondLevel(state),
  thirdLevel: selectors.thirdLevel(state),
  items: selectors.secondLevelItems(state),
});

export default connect(mapStateToProps)(SecondLevelNav);
