import React from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import * as selectors from "./Nav.selectors";
import SecondLevelNavMobile from "./Nav.second.level.mobile.js";
import { clickFirstLevel } from "./Nav.actions";
import Caret from "./Nav.caret";

const FirstLevelNavMobile = ({
  items,
  dispatch,
  secondLevel,
  secondLevelVisible,
}) => (
  <div className="nav__first-level nav__first-level--mobile">
    {items &&
      items
        .sort((a, b) => parseInt(a.order || 0, 10) - parseInt(b.order || 0, 10))
        .map((item) => (
          <>
            <div
              className={classNames({
                "nav__first-level-item": true,
                "nav__first-level-item--mobile": true,
                "nav__first-level-item--active": secondLevel === item.name,
              })}
              onClick={() => dispatch(clickFirstLevel(item.name))}
            >
              <span
                className={classNames({
                  "nav__first-level-name": true,
                  "nav__first-level-name--active": secondLevel === item.name,
                })}
              >
                {item.name}
              </span>
              <div
                className={classNames({
                  nav__caret: true,
                  "nav__caret--mobile": true,
                  "nav__caret--open": secondLevel === item.name,
                })}
              >
                <Caret />
              </div>
            </div>
            {secondLevelVisible && secondLevel === item.name && (
              <SecondLevelNavMobile />
            )}
          </>
        ))}
  </div>
);

const mapStateToProps = (state) => ({
  items: selectors.firstLevelItems(state),
  secondLevel: selectors.secondLevel(state),
  secondLevelVisible: selectors.secondLevelVisible(state),
});

export default connect(mapStateToProps)(FirstLevelNavMobile);
