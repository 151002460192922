import React from "react";
import { connect } from "react-redux";
import FourthLevelNavMobile from "../Nav/Nav.fourth.level.mobile";
import * as selectors from "./Nav.selectors";

const ThirdLevelNavMobile = ({ items }) => (
  <div className="nav__third-level nav__third-level--mobile">
    {items[0].children && <FourthLevelNavMobile items={items} />}
    {!items[0].children &&
      items
        .sort((a, b) => parseInt(a.order || 0, 10) - parseInt(b.order || 0, 10))
        .map((item) => (
          <a href={item.link}>
            <div className="nav__third-level-item nav__third-level-item--mobile">
              <span className="nav__third-level-name">{item.name}</span>
            </div>
          </a>
        ))}
  </div>
);

const mapStateToProps = (state) => ({
  items: selectors.thirdLevelItems(state),
});

export default connect(mapStateToProps)(ThirdLevelNavMobile);
