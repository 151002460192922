export const firstLevel = (state) => state.firstLevel;
export const firstLevelItems = (state) => state.menu;
export const auxItems = (state) => state.auxMenu;
export const navLoaded = (state) => state.navLoaded;
export const navOpen = (state) => state.navOpen;
export const secondLevel = (state) => state.secondLevel;
export const secondLevelVisible = (state) => state.secondLevelVisible;
export const searchOpen = (state) => state.searchOpen;
export const thirdLevel = (state) => state.thirdLevel;
export const thirdLevelVisible = (state) => state.thirdLevelVisible;
export const categoryDescription = (state) => state.categoryDescription;

export const secondLevelItems = (state) => {
  if (!secondLevel(state)) return [];
  const { children } = state.menu.find((i) => i.name === state.secondLevel);
  if (!children) return [];
  return children;
};

export const thirdLevelItems = (state) => {
  if (!thirdLevel(state)) return [];
  const { children } = secondLevelItems(state).find(
    (i) => i.name === state.thirdLevel
  );

  if (!children) return [];
  return children;
};
