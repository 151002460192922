import {
  SET_NAV,
  SET_AUX_NAV,
  SET_NAV_LOADED,
  SET_NAV_OPEN,
  SET_SEARCH_OPEN,
  SET_SECOND_LEVEL,
  SET_SECOND_LEVEL_VISIBLE,
  SET_THIRD_LEVEL,
  SET_THIRD_LEVEL_VISIBLE,
  SET_CATEGORY_DESCRIPTION,
} from "./Nav.actions";

export const initialState = {};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_THIRD_LEVEL:
      return { ...state, thirdLevel: action.payload };
    case SET_THIRD_LEVEL_VISIBLE:
      return { ...state, thirdLevelVisible: action.payload };
    case SET_SECOND_LEVEL:
      return { ...state, secondLevel: action.payload };
    case SET_SECOND_LEVEL_VISIBLE:
      return { ...state, secondLevelVisible: action.payload };
    case SET_NAV_LOADED:
      return { ...state, navLoaded: action.payload };
    case SET_SEARCH_OPEN:
      return { ...state, searchOpen: action.payload };
    case SET_NAV_OPEN:
      return { ...state, navOpen: action.payload };
    case SET_NAV:
      return { ...state, menu: action.payload };
    case SET_AUX_NAV:
      return { ...state, auxMenu: action.payload };
    case SET_CATEGORY_DESCRIPTION:
      return { ...state, categoryDescription: action.payload };
    default:
      return state;
  }
};

export default reducer;
