import React from "react";
import CustomTableRow from "./CustomTableRow";
import { strings } from "../../util/i18n";

function CustomTable({ isLogged, pageId, rows, setRows }) {
  return (
    <>
      <div className="table-description">
        <span>{strings.otherBreakDown}</span>
        <span className="greyspan">{strings.pricesBeforeTax}</span>
      </div>
      <table className="products products--custom">
        <thead>
          <tr>
            {!isLogged && <th scope="col" className="col--sku"></th>}
            <th scope="col" className="col--name col--name-custom">
              {strings.productName}
            </th>
            <th scope="col" className="col--amount">
              {strings.productAmount}
            </th>
            <th scope="col" className="col--price">{`${
              strings.price
            }${"\u00A0"}á*`}</th>
            <th scope="col" className="col--vat">
              {strings.vatPercentage}
            </th>
            {isLogged && <th scope="col" className="col--x"></th>}
          </tr>
        </thead>
        <tbody>
          {!!rows.length
            ? rows.map((row) => (
                <CustomTableRow
                  row={row}
                  rows={rows}
                  setRows={setRows}
                  pageId={pageId}
                  isLogged={isLogged}
                />
              ))
            : null}
        </tbody>
      </table>
    </>
  );
}
export default CustomTable;
